import { NgModule,ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NetworkService } from './services/network.service';
import { DataService } from './services/data.service';
import { RedirectComponent } from './components/redirect/redirect.component';

import {UserService} from './services/user.service';
import { UtilService } from './services/util.service';
import { BreadcrumbComponent } from '../main/breadcrumb/breadcrumb.component'
import {
  ModalModule
} from 'ngx-bootstrap';
import {
  TooltipModule,
  MultiSelectModule,
  CalendarModule,
  GrowlModule
} from 'primeng/primeng';
import {PaginatorModule} from 'primeng/paginator';
import {TabMenuModule} from 'primeng/tabmenu';
import { TableModule} from 'primeng/table';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LoaderComponent } from './components/loader/loader.component';
import {DropdownModule} from 'primeng/dropdown';
import {DatePipe} from '@angular/common';
import {SliderModule} from 'primeng/slider';
import { ContextMenuModule } from "primeng/components/contextmenu/contextmenu";
import { AccordionModule } from 'ngx-bootstrap/accordion';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PopoverModule } from 'ngx-bootstrap';
import {ListboxModule} from 'primeng/listbox';
import { RouteGuardService } from './services/route-guard.service';
import { DefaultRouteService } from './services/default-route.service';
import {ChipsModule} from 'primeng/chips';
import { ForwardCommandExtendedComponent } from './components/forward-command-extended/forward-command-extended.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    TooltipModule,
    GrowlModule,
    TableModule,
    TabMenuModule,
    BreadcrumbModule,
    OverlayPanelModule,
    PaginatorModule,
    DropdownModule,
    AccordionModule.forRoot(),
    ProgressbarModule.forRoot(),
    PopoverModule.forRoot(),
    CalendarModule,
    ContextMenuModule,
    SliderModule,
    ConfirmDialogModule,
    MultiSelectModule,
    ListboxModule,
    ChipsModule
  ],
  declarations: [FileUploadComponent,LoaderComponent,RedirectComponent,BreadcrumbComponent, ForwardCommandExtendedComponent],
  exports:[ModalModule,FormsModule,FileUploadComponent,TooltipModule,LoaderComponent,AccordionModule,ContextMenuModule,BreadcrumbComponent,
      ConfirmDialogModule, GrowlModule, TableModule, TabMenuModule, BreadcrumbModule, OverlayPanelModule, ProgressbarModule, PaginatorModule, DropdownModule, CalendarModule, SliderModule, PopoverModule,MultiSelectModule,ListboxModule,ChipsModule, ForwardCommandExtendedComponent],
  providers:[NetworkService,DatePipe,ConfirmationService,UserService, UtilService,RouteGuardService,DefaultRouteService]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        NetworkService,ConfirmationService
        ]
    };
  }
 }
