import { Injectable} from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class UserService {
  constructor(private msalService: MsalService) {
  }
  
  msalDetails = {
    "userInfo": {},
    "token": {}
  }

  isTokenValid() {
    if (localStorage.getItem('msalDetails')) {
      let exp = JSON.parse(localStorage.getItem('msalDetails')).userInfo.exp;

      if (+new Date() < exp * 1000) { return true; }
      else { this.msalService.loginRedirect(); } 
    }

    return false;
  }

  getUserDetails() {
    let userDetails = JSON.parse(localStorage.getItem('msalDetails')).userInfo;
    return userDetails;
  }

  getToken() {
    //let token = JSON.parse(localStorage.getItem('msalDetails')).token;
    let token = sessionStorage.getItem('msal.accesstoken');
    return token;
  }

  checkUserrights(roleKey?){
    let roleList:any={}
    roleList=JSON.parse(localStorage.getItem('UserRightsSession'))
    if(roleList.hasOwnProperty(roleKey)){
      if(roleList[roleKey]==true){
        return true
      }
      else{
        return false
      }
    }
    else 
    return false
 
  }
  checkUserRole(role) {
    let roleArray = JSON.parse(sessionStorage.getItem('mainRoles'));
    let plantId = JSON.parse(sessionStorage.getItem('locationDetails')).id;
    let siteObj: any;
    if (!roleArray.includes("admin")) {
      for (let item of roleArray) {
        if (item.siteID == plantId) {
          siteObj = item;
        }
      }
      return siteObj.roles.includes(role)
    }
    return false;
  }

}
