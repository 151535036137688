import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../services/data.service';
import { NetworkService } from '../../services/network.service';
import { SpinnerHandlerService } from '../../services/spinner-handler.service';

@Component({
  selector: 'app-forward-command-extended',
  templateUrl: './forward-command-extended.component.html',
  styleUrls: ['./forward-command-extended.component.less']
})
export class ForwardCommandExtendedComponent implements OnInit {
  deviceDetailsList: any;
  IsBroadcast: boolean;
  deviceList: any;
  errmsg: any;
  commandCode: any;
  selectList: any[];
  commandArguments: any = [{ "val": '' }];
  commandName: any;
  fcList: any;
  commandRes: any;
  deviceProfile: any;
  isChoosen: any;
  isCorrect: any;
  deviceId: any;
  selectedDevice: any;
  setPriority:string='';
  selectedCommandInfo : any;
  fileVersion: string;
  @ViewChild('forwardCommandModal') forwardCommandModal;
  @Input() show;
  @Input() deviceProfileValue;
  @Input() deviceType: any;
  @Input() editData;
  @Input() screen = "";
  @Input() commandObject;
  @Input() commandIndex;
  @Input() filteredProfileWithFile;
  @Input() fieldVersionObject;
  @Output() close = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<any>();
  showPop: boolean = false;
  IsRolloutScreen: boolean = false;
  constructor(private dataService: DataService, private networkService: NetworkService, private spinnerHandler: SpinnerHandlerService) { }

  ngOnChanges(...args: any[]) {
    if(this.screen == ""){
      this.screen = "DEVICEDETAILS"
    }

    if(this.screen == "InitialRollout"){
      this.IsRolloutScreen = true;
    }
    
    this.screen = this.screen.toLocaleUpperCase();
    
    //console.log(args[0].show);
    if(typeof args[0].show === 'undefined') {
      //do nothing
    }
    else{
      this.showPop = args[0].show.currentValue;
    }

    if (this.showPop) {
      this.deviceProfile = JSON.parse(localStorage.getItem('deviceProfile'));
      this.selectedDevice = this.dataService.getDataArray();
      if(this.selectedDevice != null && this.screen == "DEVICEDETAILS"){
        if (this.selectedDevice.length >= 1) {
          this.deviceType = this.selectedDevice[0].DeviceType;
          this.deviceList = [];
          this.IsBroadcast = true;
          this.deviceDetailsList = [];
          this.selectedDevice.forEach(device => {
            this.deviceList.push(device.DeviceId);
            this.deviceDetailsList.push({ 'DeviceId': device.DeviceId, 'EquipmentId': device.EquipmentId })
          });
        }
        else {
          this.deviceType = this.selectedDevice.DeviceType;
          this.deviceId = this.selectedDevice.DeviceId;
          this.IsBroadcast = false;
        }
      }
      
      this.getCommandList();
      this.commandName = '';
      this.isCorrect = true;
      this.isChoosen = false;
      this.errmsg = '';
      this.forwardCommandModal.show();
      //this.commandName = "Install Package";
      this.selectedCommandInfo = this.commandObject.filter(o=> o.CommandIndex == this.commandIndex);
      
      this.commandName = this.selectedCommandInfo[0] != null ? this.selectedCommandInfo[0].CommandName : null;
    }
  }
  
  ngOnInit() {
    this.isCorrect = true;
    this.isChoosen = false;
    this.commandName = '';
    this.errmsg = '';
  }

  closePopup() {
    for (let item of this.commandArguments) {
      item.b = '';
    }
    this.commandArguments = [];
    this.errmsg = '';
    this.close.emit(true);
    this.forwardCommandModal.hide();
  }

  getCommandList() {
    let request: any = [];
    // request.DeviceType = this.deviceType;
    // request.DeviceProfile = this.deviceProfile.Value;

    if(this.screen == 'DEVICEDETAILS'){
      request = {
        "SearchInfo": {
          "deviceprofile": this.deviceProfile.Value,
          "devicetype": this.deviceType
        },
        "Endpoint": "ForwardCommandfetchapi",
        "EndpointRoute": "/devicecommand"
      }
    }
    else{
      request = {
        "SearchInfo": {
          "deviceprofile": this.deviceProfileValue,
          "devicetype": this.deviceType
        },
        "Endpoint": "ForwardCommandfetchapi",
        "EndpointRoute": "/devicecommand"
      }
    }
    
    this.networkService.httpCall('post', 'api/platform/request', request)
      .subscribe(res => {
        this.commandRes = JSON.parse(res);
        this.fcList = this.getUnique(this.commandRes.C2DCommands, 'DisplayName');
        this.fcList.sort(function (a, b) {
          return a.DisplayName.localeCompare(b.DisplayName);
        })

         if(this.IsRolloutScreen) {
           this.getcommandHeader();
         }
      });
  }

  getUnique(arr, comp) {
    const unique = arr
      .map(e => e[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }

  getcommandHeader() {
    this.isChoosen = true;
    this.isCorrect = false;
    this.commandArguments = [];
    this.selectList = [];
    for (let item of this.fcList) {
      if (item.Name == this.commandName) {
        this.commandCode = item.Code;
        this.commandArguments = item.Arguments; // Set Value Here and make changes in UI
        this.setPriority=item.Priority.split('|')[1];
        this.commandArguments.forEach(arg => {
          let val = this.selectedCommandInfo[0].CommandArguments.find(o => o.a == arg.Key);
          if(val != null && val != undefined) arg.b = val.b;
        });
        break;
      }
    }

    if (this.commandArguments == null) {
      this.commandArguments = [];
    }
    else {
      for (let item of this.commandArguments) {
        if (item.InputDataCollection) {
          this.selectList = [];
          let dataCollection: string = item.InputDataCollection;
          dataCollection.split(';').forEach(value => {
            let resultObj = {};
            resultObj['key'] = value.split('|')[0];
            resultObj['value'] = value.split('|')[1];
            this.selectList.push(resultObj);
          });
        }
      }
    }
  }

  clearCommand() {
    if(!this.IsRolloutScreen) {
      this.commandArguments = [];
      this.isChoosen = false;
      this.commandName = '';
      this.isCorrect = true;
    }

    for (let item of this.commandArguments) {
      if (item.InputControl == "LookUp") { delete item.b; }
        else { item.b = ''; }
       
       if(!this.IsRolloutScreen) {
         this.commandArguments= [];
       }
    }
    
    this.errmsg = '';
  }

  sendForwardCommand() {
    let msalDetails:any
    msalDetails=JSON.parse(localStorage.getItem('msalDetails')).userInfo.name
    for (let item of this.commandArguments) {
      item["a"] = item["Key"];
       if(item.InputControl=="Hidden"){
        item.b=null
      }
    }

    if (this.IsBroadcast) {
      let req: any = [];
      req = {
        "deviceDetailsList": this.deviceDetailsList,
        "commandRequest": {
          "DeviceType": this.deviceType,
          "CommandName": this.commandName,
          "Priority": this.setPriority,
          "Location": "Land",
          "SmsArguments": [],
          "CommandArguments": this.commandArguments,
          "TtlMinutes": 120,
          "ApiCaller": 'PlatformUI',
          "deviceProfile": this.deviceProfile.Value
        }
      };
      this.networkService.httpCall("post", 'api/reefer/forwardcommandBroadcast', req)
        .subscribe(res => {
          this.closePopup();
          this.dataService.broadcast("toastr", { type: 'success', message: 'Forward command issued successfully' });
          /*if (res) {
            if (res.StatusCode == 200) {
              let msg = res.Message;
              this.errmsg = '';
              this.closePopup();
              this.dataService.broadcast("toastr", { type: 'success', message: msg });
            }
            else {
              let msg = 'Error occured.';
              this.closePopup();
              this.dataService.broadcast("toastr", { type: 'error', message: msg });
            }
          }*/
        }/*,
        err => {
          this.errmsg = err.error.Message;
          this.spinnerHandler.toggleSpinner(0);
        }*/);
    }
    else {
      let req: any = [];
      req = {
        "DeviceId": this.deviceId,/*"wcddevice1",*/
        "EquipmentId": this.selectedDevice.EquipmentId,        
        "CommandName": this.commandName,
        "Priority": this.setPriority,
        "VesselImoCode":'',
        "Imsi":'',       
        "CommandArguments": this.commandArguments,
        "TtlMinutes": 120,
        "ApiCaller": 'PlatformUI'
      };      
      this.networkService.httpCallNoError("post", 'api/v2/forwardcommand', req, '', 'forwardCMD')
        .subscribe(res => {
          if (res) {
            if (res.StatusCode == 200) {
              let msg = res.Message;
              this.errmsg = '';
              this.closePopup();
              this.dataService.broadcast("toastr", { type: 'success', message: msg });
            }
            else {
              let msg = 'Error occured.';
              this.closePopup();
              this.dataService.broadcast("toastr", { type: 'error', message: msg });
            }
          }
        },
        err => {
          this.errmsg = err.error.Message;
          this.spinnerHandler.toggleSpinner(0);
        });
    }
  }

  onChange(event) {
    return false
  }

  removeI(item) {
    this.deviceList.push(item.value)
  }

  SaveSelectedCommand(){
    //let fileVw = '-1';
    this.fileVersion = '-1'; 
    this.commandObject[this.commandIndex-1].CommandArguments = [];
    this.commandArguments.some(element => {
            const childObj = {
              "DisplayName": element.KeyDisplayName,
              "b":element.b,
              "a":element.Key
            }
            //this.commandObject.commandArguments.push(childObj);            
            //let fileVw = this.FindFileVersion(element.b);
            
            if(element.KeyCode.toLowerCase() != 'filename'){              
              this.commandObject[this.commandIndex-1].CommandArguments.push(childObj);
            }
            else{
              this.fileVersion = this.FindFileVersion(element.b);
              console.log('Version for FileName: ' + element.b + ' = ' + this.fileVersion);
              if(this.fileVersion == '-1'){
                console.log('Inner1: File name does not exist');
                this.errmsg = 'Following file name does not exist in Software Repository';    
                this.commandObject[this.commandIndex-1].CommandArguments = [];    
                throw new Error("File name does not exist");        
                //return;
              }
              else{
                this.commandObject[this.commandIndex-1].CommandArguments.push(childObj);
                /*this.fieldVersionObject.some(elem => {
                  if((elem.keycodefield.toLowerCase() == element.KeyCode.toLowerCase()) && (this.commandName.toLowerCase() == elem.CommandName.toLowerCase()) ){
                    elem.Value = this.fileVersion;
                    elem.fileNm = element.b
                    console.log('File version exist')
                  }
                  else{
                    console.log('Inner2: File name does not exist');
                    this.errmsg = 'Following file name does not exist in Software Repository';
                    throw new Error("File name does not exist");  
                    //return;
                  }
                });*/

                this.fieldVersionObject.forEach(elem => {
                  if((elem.keycodefield.toLowerCase() == element.KeyCode.toLowerCase()) && (this.commandName.toLowerCase() == elem.CommandName.toLowerCase()) ){
                    elem.Value = this.fileVersion;
                    elem.fileNm = element.b
                    console.log('File version exist')
                  }
                  else{
                    console.log('Inner2: Command Name did not match');
                    //this.errmsg = 'Following file name does not exist in Software Repository';
                    ///throw new Error("File name does not exist");  
                    //return;
                  }
                });
              }   
          }         
      });
       /*
        if(this.fileVersion == '-1'){
          console.log('Outer: File name does not exist');
          this.errmsg = 'File name does not exist'; 
          this.commandObject[this.commandIndex-1].CommandArguments = [];       
          return;
        }*/
      this.save.emit(JSON.parse(JSON.stringify(this.commandObject)));
      this.save.emit(JSON.parse(JSON.stringify(this.fieldVersionObject)));
      this.closePopup();
  }

  FindFileVersion(fileNm) {
    let verVal = '-1';    
    this.filteredProfileWithFile.forEach(el => {  
      if(el.FileName != null){
        if(el.FileName.toLowerCase() == fileNm.toLowerCase()){
          verVal = el.Version;
          return verVal;
        };
      }
    }); 
    return verVal;
  }  
}
