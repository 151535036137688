import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../shared/services/user.service';
import { NetworkService } from "../shared/services/network.service";
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Logger, CryptoUtils, AuthenticationParameters } from 'msal';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  formSubmitted: boolean;
  loginData: any = {
    userName: null,
    password: null
  };
  returnUrl;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: MsalService,
    private userService: UserService,
    private networkService: NetworkService) {

  }
  
  ngOnInit() {
    // localStorage.setItem('redirectURL',state.url)
    // console.log('this',this.router.url)
    this.login();
  }

  // loginWithAzure() {
  //   let redirectURL=localStorage.getItem('redirectURL')
  //   // if(!this.userService.isTokenValid()){
  //     // this.adalService.login();
  //   // }
  //   // else
  //   // {
  //     // this.router.navigate([redirectURL])
  //   // }
  //   let sessionAdal = JSON.parse(localStorage.getItem('adalDetails'));      
  //   let req: any = [];
  //   req.userEmail = sessionAdal.userInfo.upn;

  //   this.networkService.httpCall("get", 'api/isActiveUser', req).subscribe(res => {
  //     this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';
  //     if (res.IsActive == true) {
  //       this.router.navigate(['main/dashboard/access-denied']);
  //     }
  //   })  
  // }



  login() {

    let accessTokenRequest = {};
    accessTokenRequest["scopes"] = environment.msalConfig.scopeUri;
    this.authService.loginRedirect();
      
    this.authService.acquireTokenSilent(accessTokenRequest).then(function (accessTokenResponse) {
       // Acquire token silent success
        // Call API with token
        let accessToken = accessTokenResponse.accessToken;
        sessionStorage.setItem('msal.accesstoken', accessToken);

      }).catch(function (error) {	
        //Acquire token silent failure, and send an interactive request	
        if (error.errorMessage.indexOf("interaction_required") !== -1) {	
          this.authService.acquireTokenRedirect(accessTokenRequest).then(function (accessTokenResponse) {	
            // Acquire token interactive success	
            //console.log("Acquire token interactive success");	
          });	
        }	
      });
  }
}

