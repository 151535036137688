import { Component, OnInit } from '@angular/core';
import { ElementRef, Renderer2 } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  @Output() onFileSelect: EventEmitter<any> = new EventEmitter();
  uploadedFile: any;

  constructor(private renderer: Renderer2,
    private elementRef: ElementRef) {
  }

  ngOnInit() {
  }

  openFileSelect() {
    setTimeout(() => {

    });
    this.elementRef.nativeElement.querySelector('input').click();
  }

  fileSelected(e) {
    // console.log("events0",files)
    // if (files.length === 0) 
    // {
    //   return; 
    // } 
    
    this.filedata=e.srcElement.files[0];
  let formData :FormData= new FormData();  
  formData.append('file',this.filedata,this.filedata.name);
  // console.log("formData",formData.get('file'))

  // for (let file of files) {
  //   debugger;
  //   console.log("file",file)
    
  //   // formData.append("sdasd","sd"); 
  // } 
    this.uploadedFile = this.elementRef.nativeElement.querySelector('input').files[0];
    this.onFileSelect.emit(this.filedata);
  }
filedata:any
}
