import {
  Injectable
} from '@angular/core';
import {
  HttpEvent,
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import {
  Observable
, 
  of
} from 'rxjs';
import {
  catchError,
  tap,
  finalize
} from 'rxjs/operators';
import {
  DataService
} from "./data.service";

import {
  SpinnerHandlerService
} from "./spinner-handler.service";
import {
  Router
} from '@angular/router';

import {
  environment
} from "./../../../environments/environment";

@Injectable()
export class NetworkService {

  serviceCount = 0;
  options;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private spinnerHandler: SpinnerHandlerService,
    private router: Router,
  ) {

  }

  httpCall(type, endUrl, data?, header?, profile?, respType?): Observable<any> {
    let url = '';
    if (profile == 'beacon') {
      url = environment.beacon + endUrl;
    }
    else if (profile == 'meip') {
      url = environment.meip + endUrl;
    }
    else if (profile == 'forwardCMD') {
      url = environment.forwardCMD + endUrl;
    }
    else if (profile == 'baseVV') {
      url = environment.vesselSWDep + endUrl;
    }
    else if (profile == 'decommission') {
      url = environment.decommission + endUrl;
    }
    else {
      url = environment.base + endUrl;
    }
    this.spinnerHandler.toggleSpinner(1);
    switch (type) {
      case 'get':
        return this.http.get(this.encodeURL(url, data), this.setHeaders(header, respType))
          .pipe(tap(res => this.handleHTTPSuccess(res)),
            catchError(this.handleError('error', [])));

      case 'post':
        return this.http.post(url, data, this.setHeaders(header, respType))
          .pipe(tap(res => this.handleHTTPSuccess(res)),
            catchError(this.handleError('error', [])));

      case 'put':
        return this.http.put(url, data, this.setHeaders(header))
          .pipe(tap(res => this.handleHTTPSuccess(res)),
            catchError(this.handleError('error', [])));

      case 'delete':
        return this.http.delete(url, this.setHeaders(header))
          .pipe(tap(res => this.handleHTTPSuccess(res)),
            catchError(this.handleError('error', [])));
    }
  }

  getSWResponse(): Observable<any> {
    let url = '';
    url = '../../../assets/files/swDepRepsonse.json';
    const httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      }),
      // responseType: 'arraybuffer',
      // observe: 'response' as 'json'
    };
    // return this.http.get<any>(this.encodeURL(url, data), httpOptions);
    return this.http.get<any>(url, httpOptions);
  }

  httpCallNoError(type, endUrl, data?, header?, profile?): Observable<any> {

    let url = '';
    if (profile == 'beacon') {
      url = environment.beacon + endUrl;
    }
    else if (profile == 'meip') {
      url = environment.meip + endUrl;
    }
    else if (profile == 'forwardCMD') {
      url = environment.forwardCMD + endUrl;
    }
    else if (profile == 'baseVV') {
      url = environment.vesselSWDep + endUrl;
    }
    else {
      url = environment.base + endUrl;
    }
    this.spinnerHandler.toggleSpinner(1);
    switch (type) {
      case 'get':
        return this.http.get(this.encodeURL(url, data), this.setHeaders(header))
          .pipe(tap(res => this.handleHTTPSuccess(res)));

      case 'post':
        return this.http.post(url, data, this.setHeaders(header))
          .pipe(tap(res => this.handleHTTPSuccess(res)));

      case 'put':
        return this.http.put(url, data, this.setHeaders(header))
          .pipe(tap(res => this.handleHTTPSuccess(res)));

      case 'delete':
        return this.http.delete(url, this.setHeaders(header))
          .pipe(tap(res => this.handleHTTPSuccess(res)));
    }
  }

  setHeaders(headerVal, respType?) {
    if (headerVal) {
      if (headerVal == 'file') {
        return {
          headers: new HttpHeaders({
            // 'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
          })
        };
      }
    }
    // else if(headerVal){
    //     if(headerVal=='deplFile'){
    //         return {
    //             headers: new HttpHeaders({
    //                 'content-type': 'application/octet-stream',
    //                 // 'Accept': 'application/json'
    //             })
    //         }; 
    //     }
    // }

    else {
      if (respType) {
        let options: Object = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            // 'Accept': 'application/json'
          }),
          responseType: 'arraybuffer'
        }
        return options;
      }
      else {
        return {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
            // 'Accept': 'application/json'
          })
        };
      }

    }
  }

  handleHTTPSuccess(result) {
    // if (result.status === 401) 
    // { 
    // }
    this.spinnerHandler.toggleSpinner(0);
    return result;
  }

  handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {
      let errMsg = '';
      this.spinnerHandler.toggleSpinner(0);
      if (error && error.error) {
        errMsg = (error.error.errorMessage || error.message);
      }
      this.showError(errMsg);
      this.spinnerHandler.toggleSpinner(0);
      return of(result as T);
    };
  }

  showError(errorMsg) {
    //  this.dataService.broadcast("toastr", { type: 'error', message: errorMsg });
  }

  encodeURL(url, data) {
    let encodedUrl = url;
    if (data) {
      encodedUrl = encodedUrl + "?" + Object.keys(data).map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
      })
        .join('&');
    }
    return encodedUrl;
  }

  getJSON(): Observable<any> {
    return this.http.get("./assets/files/deviceInvHeader.xlsx");
  }

}
