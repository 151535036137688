import { Injectable } from '@angular/core';
import { CanActivate ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UserService } from './user.service';

@Injectable()
export class DefaultRouteService implements CanActivate {
// userRights
  constructor(private userService: UserService, private msalService: MsalService ,private router:Router) {
   }

  canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot ) {
    // console.log('routeActive',route.data.roleKey)
    // console.log('route',state.url)
    let userRights=JSON.parse(localStorage.getItem('UserRights'))
    let routeUrl = state.url;
  // console.log('istokenvalid',this.userService.isTokenValid())
    if (this.userService.isTokenValid()) {
      let selectedRouteFlag=route.data.roleKey
      let selectedPage=route.data.page
      if(route.data.roleKey!=undefined){
        if(this.userService.checkUserrights(route.data.roleKey)){
          return true;
        }
        else{
          if(selectedPage=='devices'){
            console.log('sds',route.data.status)
            if(selectedRouteFlag=='EnableDevicesLeftMenu'){
              if(!userRights.EnableDevicesLeftMenu && userRights.EnableCommandsLeftMenu){
                this.router.navigate(['main/devices/commands-status']);
                return false
              }
            }
          }
          else if(selectedPage=='software'){
            if(selectedRouteFlag=='EnableSoftwareRepository'){
              if(!userRights.EnableSoftwareRepository && userRights.EnableSoftwareDeployment){
                this.router.navigate(['main/software/deployment-status']);
                return false
              }
            }
          }
          else if(selectedPage=='admin'){
            if(selectedRouteFlag=='EnableUserManagement'){
              if(!userRights.EnableUserManagement && userRights.EnableRoleManagement){
                this.router.navigate(['main/admin/role-management']);
                return false
              }
            }
          }
        }
      }
      
      // this.router.navigateByUrl(state.url)
    return true;
      // this.router.navigate([routeUrl])
    } else {
      this.router.navigate(['/login']);
      // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }})
      return false;
    }
  }
}