import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit, OnDestroy {
  subscription;
  loaderFlag: boolean;
  messageBox;
  spinner;
  toaster;

  constructor(private dataService: DataService) {
  }

  ngOnInit() {
    this.spinner = this.dataService.on('spinner', this.setFlag);
    this.toaster = this.dataService.on('toastr', this.displayToastr);
  }

  setFlag = (res) => {
    this.loaderFlag = res;
  }

  displayToastr = (res) => {
    this.messageBox = [];
    this.messageBox.push({ severity: res.type, summary: res.type.toUpperCase(), detail: res.message });
  }

  ngOnDestroy() {
  }
}