import { Injectable } from '@angular/core';

@Injectable()
export class UtilService {

  constructor() { }

  convertToUTCTime(time) {
    return (+time) + (new Date().getTimezoneOffset() * 60 * 1000);
  }

  convertToLocalTime(time) {
    return (+time) - (new Date().getTimezoneOffset() * 60 * 1000);
  }

  encodeURL(url, data) {
    let encodedUrl = url;
    if (data) {
      encodedUrl = encodedUrl + "?" + Object.keys(data).map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
      }).join('&');
    }
    return encodedUrl;
  }

  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};

getParamsObjectFromHash() {
  const hash = window.location.hash ? window.location.hash.split('#') : [];
  let toBeReturned = {};
  if (hash.length && hash[1].split('&').length) {
    toBeReturned = hash[1].split('&').reduce((acc, x) => {
      const hello = x.split('=');
      if (hello.length === 2) acc[hello[0]] = hello[1];
        return acc;
    }, {});
  }
  return Object.keys(toBeReturned).length ? toBeReturned : null;
}

isJson(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

}
