import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ConditionalGuard implements CanActivate {
  constructor(private msalGuard: MsalGuard) {
    
  }
  

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): any { 
    return this.msalGuard.canActivate(_route, _state);
  }
  
}
