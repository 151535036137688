export const environment = {
  production: true,
  base: "https://webazewpmlitiot-dmapi.azurewebsites.net/",
  beacon: "https://mlitbeaconazreswebappwedev.azurewebsites.net/",
  meip: "https://webazewpmlitiot-dpapi.azurewebsites.net/",
  forwardCMD: "https://webazewpmlitiot-c2dapi.azurewebsites.net/",
  msalConfig: {
    tenant: '05d75c05-fa1a-42e7-9cf1-eb416c396f2d',
    clientId: 'b8e33181-b4d4-4bf6-a686-1e3cf140b357',
    redirectUri: 'http://localhost:4200/',
    postLogoutRedirectUri: 'http://localhost:4200/',
    scope: 'openid https://graph.windows.net/User.Read',
    responseMode: 'fragment',
    responseType: 'id_token token',
    //expireOffsetSeconds: 3480
    Authority: 'https://login.microsoftonline.com/',
    scopeUri: ['api://b8e33181-b4d4-4bf6-a686-1e3cf140b357/user_impersonation'],
    scopeUriAll: ["api://b8e33181-b4d4-4bf6-a686-1e3cf140b357/user_impersonation","user.read","openid","profile","https://graph.microsoft.com/.default"],
    scopeUriWithoutGraph: ["api://b8e33181-b4d4-4bf6-a686-1e3cf140b357/user_impersonation","user.read","openid","profile"]
  },
  vesselSWDep: 'https://fapazewpmlitvcmiot-vesselmonitoring.azurewebsites.net/',
  decommission: 'https://fapazewpmlitvcmiot-vesseldeploymentpipeline.azurewebsites.net/'
};
