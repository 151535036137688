import { Injectable } from '@angular/core';
import { DataService } from "./data.service";

@Injectable()
export class SpinnerHandlerService {
  serviceCount = 0;
  constructor(private dataService: DataService) { }

  toggleSpinner(serviceFlag) {
    switch (serviceFlag) {
      case 0: this.serviceCount -= 1;
        if (this.serviceCount <= 0) {
          this.serviceCount = 0;
          this.dataService.broadcast("spinner", false);
        }
        break;
      case 1: this.serviceCount += 1;
        this.dataService.broadcast("spinner", true);
        break;
      case 2: this.dataService.broadcast("spinner", false);
        break;
    }
  }

}
