import { Component } from '@angular/core';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {UtilService} from './shared/services/util.service';
import {UserService} from './shared/services/user.service';
import { NetworkService } from "./shared/services/network.service";
import {
  environment
} from "../environments/environment";
import { AuthenticationParameters, CryptoUtils, Logger } from 'msal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'app';
  sessionStorageParamsIdToken: any;
  parsedJwt: any;
  loggedIn = false;
  

  constructor(private userService: UserService,private authService: MsalService,private utilService: UtilService,private router: Router,
    private activatedRoute: ActivatedRoute,private broadcastService: BroadcastService,
    private networkService: NetworkService) {
  }
  ngOnInit(): void{

        this.sessionStorageParamsIdToken = sessionStorage.getItem('msal.idtoken');
        if(this.sessionStorageParamsIdToken){
          this.parsedJwt = this.utilService.parseJwt(this.sessionStorageParamsIdToken);
          this.userService.msalDetails.userInfo = this.parsedJwt;
          this.userService.msalDetails.token = this.sessionStorageParamsIdToken;
          localStorage.setItem("msalDetails", JSON.stringify(this.userService.msalDetails));
        }

        this.broadcastService.subscribe('msal:loginSuccess', () => {
        this.checkoutAccount();
        sessionStorage.setItem('username', this.authService.getAccount().name);
        sessionStorage.setItem('email', this.authService.getAccount().userName); 
        this.setMsalAccessToken();
        this.loggedIn = true;        
      });
      
      this.authService.handleRedirectCallback((authError, response) => {
        if (authError) {
          console.error('Redirect Error: ', authError.errorMessage);
          return;
        }
      });
      this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      }, {
        correlationId: CryptoUtils.createNewGuid(),
        piiLoggingEnabled: false
      }));
  }

  checkoutAccount() {    
    this.loggedIn = !!this.authService.getAccount();
    if (this.loggedIn) { 
      this.router.navigate(['/redirect']);
    }
    else {
      this.router.navigate(['/login']);
    }
  }

  setMsalAccessToken(){
    let accessTokenRequest = {};
    accessTokenRequest["scopes"] = environment.msalConfig.scopeUri;

    this.authService.acquireTokenSilent(accessTokenRequest).then(function (accessTokenResponse) {
      // Acquire token silent success
      // Call API with token
      let accessToken = accessTokenResponse.accessToken;
      sessionStorage.setItem('msal.accesstoken', accessToken);
    }).catch(function (error) {
      //Acquire token silent failure, and send an interactive request
      
      if (error.name === "InteractionRequiredAuthError") {
        return this.authService.acquireTokenRedirect(accessTokenRequest)
            .then(response => {
                // get access token from response
                // response.accessToken
                let token = response.accessToken;
                sessionStorage.setItem('msal.accesstoken', response.accessToken);
            })
          };
      if (error.errorMessage.indexOf("interaction_required") !== -1) {
        this.authService.acquireTokenRedirect(accessTokenRequest);
      }
    });
  }
} 
