import { Injectable } from '@angular/core';
import { CanActivate ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UserService } from './user.service';

@Injectable()
export class RouteGuardService implements CanActivate {

  constructor(private userService: UserService, private msalService: MsalService ,private router:Router) {
    
   }

  canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot ) {
    // console.log('routeActive',route.data.roleKey)
    // console.log('route',state.url)
    let routeUrl = state.url;
  // console.log('istokenvalid',this.userService.isTokenValid())
    if (this.userService.isTokenValid()) {
      if(route.data.roleKey!=undefined){
        if(this.userService.checkUserrights(route.data.roleKey)){
          return true;
        }
        else{
          this.router.navigate(['main/dashboard']);
          return false
        }
      }
      
      // this.router.navigateByUrl(state.url)
    return true;
      // this.router.navigate([routeUrl])
    } else {
      this.router.navigate(['/login']);
      // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }})
      return false;
    }
  }
}

