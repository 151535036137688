import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './shared/services/token-interceptor.service';
import { DataService } from './shared/services/data.service';
import { SpinnerHandlerService } from './shared/services/spinner-handler.service';
import { ToastrModule } from 'ngx-toastr';
import { LoginComponent } from './login/login.component';
import {
  MsalModule,
  MsalInterceptor,
  MSAL_CONFIG,
  MSAL_CONFIG_ANGULAR,
  MsalService,
  MsalAngularConfiguration,
  MsalGuard
} from '@azure/msal-angular';
import { environment } from '../../src/environments/environment';
import { Configuration } from 'msal';

export const protectedResourceMap: [string, string[]][] = [
  [environment.base,environment.msalConfig.scopeUri],
  [environment.forwardCMD,environment.msalConfig.scopeUri]
];
export const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: environment.msalConfig.clientId,
      authority: environment.msalConfig.Authority + environment.msalConfig.tenant,
      validateAuthority: true,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      navigateToLoginRequestUrl: true,
    },
    system: { loadFrameTimeout: 10000 },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  };
}

export function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: false,
    consentScopes: environment.msalConfig.scopeUri,
    unprotectedResources: ["https://www.microsoft.com/en-us/"],
    protectedResourceMap,
    extraQueryParameters: {}
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot(),
    MsalModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }, 
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },
    MsalGuard,
    DataService,
    SpinnerHandlerService,
    MsalService,
    AppRoutingModule,
    HttpClientModule,
],
  bootstrap: [AppComponent]
})

export class AppModule { }
