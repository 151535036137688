import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RedirectComponent } from './shared/components/redirect/redirect.component';
import { ConditionalGuard } from './shared/services/conditional/conditional.guard';


const appRoutes: Routes = [
    // { path: '', loadChildren: 'app/main/main.module#MainModule'},
    // { path: 'main', loadChildren: 'app/main/main.module#MainModule' }
    { path: '', component: LoginComponent},
    { 
        path: 'login', component: LoginComponent
    },
    { 
        path: 'main', 
        loadChildren: 'app/main/main.module#MainModule',
        canActivate: [
            ConditionalGuard
          ]
    },
    { path: 'redirect', component: RedirectComponent },
    { path: '**', redirectTo: '/redirect', pathMatch: 'full' }, 
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            {
                useHash: true,
                onSameUrlNavigation: 'reload',
                initialNavigation: isInIframe() ? 'disabled' : undefined 
            },
        )
    ],
    exports: [
        RouterModule
    ],
    providers: []
})

export class AppRoutingModule { }

export function isInIframe() {
    return window !== window.parent && !window.opener;
  }