
import {from as observableFrom,  Observable ,  Subject } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class DataService {
  item: any;
  data = {};
  events;
  listeners = {};
  constructor() {
    this.events = observableFrom(this.subject);
    this.events.subscribe(
      ({ name, args }) => {
        if (this.listeners[name]) {
          for (const listener of this.listeners[name]) {
            listener(...args);
          }
        }
      });
  }

  private subject = new Subject<any>();

  sendMessage(message: any) {
    this.subject.next({ text: message });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  on(name, listener) {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }
    this.listeners[name].push(listener);
  }

  broadcast(name, ...args) {
    this.subject.next({
      name,
      args
    });
  }

  removeListener(arr) {
    for (const item of arr) {
      delete this.listeners[item];
    }
  }

  getData(key) {
    return this.data[key];
  };

  setData(key, value) {
    this.data[key] = value;
  };

  setDataArray(value) {
    this.item = value;
  }

  getDataArray() {
    return this.item;
  }

  clearData() {
    this.data = {};
  }

  checkMaxMultitextContent(data, limit) {
    console.log("arg", data.Value)
    var count = data.Value.split(",").length;
    if (count > limit) {
      return true;
    }
    else {
      return false;
    }
  }
}
