import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from './../../services/user.service';
@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  urlParams: any;
  parsedJwt: any;
  redirectUrl: any;
  userDetails: any;

  constructor(private router: Router, private userService: UserService) {
  }
  ngOnInit() {
    if (localStorage.getItem('redirectURL')) {
      this.redirectUrl = localStorage.getItem('redirectURL').substring(1); 
      // this.router.navigate([this.redirectUrl])     
      // console.log('this.redirectUrl',this.redirectUrl)
    }
    
    if (localStorage.getItem('msalDetails')) {
      this.userDetails = this.userService.getUserDetails();
    }

    if (this.userService.isTokenValid()) {
      // if(this.redirectUrl){
      //   this.router.navigate([this.redirectUrl])
      // }

      // else{
        this.router.navigate(['/main']).then(() => {
          window.location.reload();
        });
      // }
   
      // 
    }
    else {
      this.router.navigate(['/login']);
    }

  }

}
